import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AlertDialogComponent } from './alert-dialog.component';
import { ConfirmDialogComponent } from './confirm-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor(private dialog: MatDialog) {}

  alert(message: string, title: string = 'Alert'): Promise<boolean> {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '300px',
      data: { title, message },
      disableClose: true
    });

    return dialogRef.afterClosed().toPromise();
  }

  confirm(message: string, title: string = 'Confirm'): Promise<boolean> {
    // Small delay to prevent the Enter key from propagating
    return new Promise((resolve) => {
      setTimeout(() => {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          width: '300px',
          data: { title, message },
          disableClose: true
        });
        dialogRef.afterClosed().subscribe(result => resolve(result));
      }, 100);
    });
  }
}