<nav *ngIf="!isTVDisplay" [@simpleFadeAnimation] [@.disabled]="!isHomePage" class="navbar" [ngClass]="{'navbar-homepage': isHomePage, 'navbar-standard': isPastHero, 'navbar-open': isExpanded, 'lobby-nav': isLobbyPage}">
    <div class="navbar-left" [ngClass]="{'lobby-nav': isLobbyPage}">
        <a (click)="redirectLink('home')">
            <div class="navbar-logo">
                <scrawlbrawl-logo-horizontal></scrawlbrawl-logo-horizontal>
            </div>
        </a>
        <div id='hamburger' [ngClass]="{open: isExpanded, 'lobby-nav': isLobbyPage}" (click)="toggle()">
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
    <div class="navbar-right" [ngClass]="{'active': isExpanded, 'lobby-nav': isLobbyPage}">
        <scrawlbrawl-iconbutton (click)="toggleTheme()" class="darkmode" matTooltip="Dark Mode" [mdTooltipClass]="{'tooltip': true}">
            <ion-icon [style.fill]="'var(--text-color)'" name="contrast-outline"></ion-icon>
        </scrawlbrawl-iconbutton>
        <div class="nav-item" [routerLinkActive]="['link-active']">
            <a class="trans-link" title="Join an existing game" (click)="redirectLink('play')">Play</a>
        </div>
        <div class="nav-item" [routerLinkActive]="['link-active']">
            <a class="trans-link" title="Create a new lobby and invite people to play" (click)="redirectLink('lobby')">Host</a>
        </div>
        <div class="nav-item" [routerLinkActive]="['link-active']">
            <a class="trans-link" title="User options" (click)="redirectLink('user')">Options</a>
        </div>
    </div>
</nav>