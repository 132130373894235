import { Component, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-alert-dialog',
  template: `
    <div mat-dialog-title>{{ data.title }}</div>
    <div mat-dialog-content>
      <p>{{ data.message }}</p>
    </div>
    <div mat-dialog-actions align="end">
      <button #okBtn mat-button (click)="onClose()" cdkFocusInitial>
        OK
      </button>
    </div>
  `
})
export class AlertDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string; message: string }
  ) {}

  @HostListener('window:keydown', ['$event'])
  handleKeydown(event: KeyboardEvent) {
    event.stopPropagation();
    
    switch (event.key) {
      case "Enter":
      case " ":
      case "Escape":
        event.preventDefault();
        this.onClose();
        break;
    }
  }

  onClose(): void {
    this.dialogRef.close(true);
  }
}