import { Component, Inject, OnInit, AfterViewInit, ViewChild, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-confirm-dialog',
  template: `
    <div mat-dialog-title>{{ data.title }}</div>
    <div mat-dialog-content>
      <p>{{ data.message }}</p>
    </div>
    <div mat-dialog-actions align="end">
      <button #cancelBtn mat-button (click)="onCancel()">
        Cancel
      </button>
      <button #okBtn mat-button color="primary" (click)="onConfirm()">
        OK
      </button>
    </div>
  `
})
export class ConfirmDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('cancelBtn') cancelButton!: MatButton;
  @ViewChild('okBtn') okButton!: MatButton;
  currentButtonIndex = 0;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string; message: string }
  ) {}

  updateButtonFocus() {
    if (this.currentButtonIndex === 0 && this.cancelButton) {
      (this.cancelButton._elementRef.nativeElement as HTMLElement).focus();
    } else if (this.currentButtonIndex === 1 && this.okButton) {
      (this.okButton._elementRef.nativeElement as HTMLElement).focus();
    }
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyNavigation(event: KeyboardEvent) {
    event.stopPropagation();
    
    switch (event.key) {
      case "ArrowLeft":
        event.preventDefault();
        this.currentButtonIndex = 0;
        this.updateButtonFocus();
        break;
      case "ArrowRight":
        event.preventDefault();
        this.currentButtonIndex = 1;
        this.updateButtonFocus();
        break;
      case "Enter":
        event.preventDefault();
        if (this.currentButtonIndex === 0) {
          this.onCancel();
        } else {
          this.onConfirm();
        }
        break;
      case "Escape":
        event.preventDefault();
        this.onCancel();
        break;
    }
  }

  ngOnInit() {
  }
  
  ngAfterViewInit() {
    setTimeout(() => {
      this.updateButtonFocus();
    });
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }
}